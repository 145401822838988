 /* Dots Loading Animation */
 .dots-loading {
    margin: 0 auto;
    text-align: center;
    padding-top:4rem;
  }
  
  .dots-loading::before,
  .dots-loading::after {
    content: " ";
  }
  
  .dots-loading div,
  .dots-loading::before,
  .dots-loading::after {
    margin: 35px 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #CD1F26;
    /* background-color: #414040; */
    opacity: 0;
  }
  
  .dots-loading::before {
    -moz-animation: opacitychange 1s ease-in-out infinite;
    -webkit-animation: opacitychange 1s ease-in-out infinite;
    -o-animation: opacitychange 1s ease-in-out infinite;
    animation: opacitychange 1s ease-in-out infinite;
  }
  
  .dots-loading div {
    -moz-animation: opacitychange 1s ease-in-out 0.33s infinite;
    -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
    -o-animation: opacitychange 1s ease-in-out 0.33s infinite;
    animation: opacitychange 1s ease-in-out 0.33s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
  }
  
  .dots-loading::after {
    -moz-animation: opacitychange 1s ease-in-out 0.66s infinite;
    -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
    -o-animation: opacitychange 1s ease-in-out 0.66s infinite;
    animation: opacitychange 1s ease-in-out 0.66s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
  }
  
  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
  
    60% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
  
    60% {
      opacity: 1;
    }
  }