@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
.App-logo {
    width: 300px;
  }

.proff-top-line {
    display: block;
    background-color: #CD1F26;
    height: 5px;
  }

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 42px;
  position: relative;
  text-transform: uppercase;
  color: #02050a;
  padding-top: 10px;
  padding-bottom: 10px;
}
  .header a{
    color: #02050a;
    text-decoration: none;
  }
    .header a:hover{
      color: #02050a;
      text-decoration: none;
    }

  .header-icon {
    padding-left: 25px;
    letter-spacing: 1px;
    color:  #CD1F26;
  }

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: 0;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: fixed;
  background-color: #343A40;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  /* background-color: #CD2027; */
  color: #fff;
  text-decoration: none;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}


/* Underline Reveal */
.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #CD1F26;
  height: 3px;
  transform: translateY(4px);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before, .hvr-underline-reveal:focus:before, .hvr-underline-reveal:active:before {
  transform: translateY(0);
}

 /* Dots Loading Animation */
 .dots-loading {
    margin: 0 auto;
    text-align: center;
    padding-top:4rem;
  }
  
  .dots-loading::before,
  .dots-loading::after {
    content: " ";
  }
  
  .dots-loading div,
  .dots-loading::before,
  .dots-loading::after {
    margin: 35px 5px;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #CD1F26;
    /* background-color: #414040; */
    opacity: 0;
  }
  
  .dots-loading::before {
    -webkit-animation: opacitychange 1s ease-in-out infinite;
    animation: opacitychange 1s ease-in-out infinite;
  }
  
  .dots-loading div {
    -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
    animation: opacitychange 1s ease-in-out 0.33s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
  }
  
  .dots-loading::after {
    -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
    animation: opacitychange 1s ease-in-out 0.66s infinite;
    -webkit-animation-fill-mode: infinite;
    animation-fill-mode: infinite;
  }
  
  @keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
  
    60% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes opacitychange {
    0%,
    100% {
      opacity: 0;
    }
  
    60% {
      opacity: 1;
    }
  }
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mainColor {
  color: #CD2027;
}

a, a:hover{
  color: #CD2027;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CookieConsent {
  width: 30% !important;
}
.CookieConsent a{
  color: #fff;
  text-decoration: underline;
}
.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0;
}
 .display-6 {
  font-size: 1.7rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 0;
 }

 .border-top-1 {
  border-top: 1px solid #717171;
 }

.btn-danger {
  background-color: #CD2027;
  border-color: #CD2027;
}

.bg-danger {
  background-color: #CD2027!important;
}

.container-fluid {
  padding: 0px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  
}

.navbar-nav {
  justify-content: flex-start !important;
}

.navbar {
  padding: 0;
}

.nav-link {
  padding: 0.8rem 1rem;
}
.nav-link:hover {
  /* color: #CD1F26 !important; */
  color: #fff !important;
}
/* Front page */

/* Alert styles */
.alert, .alert-box p {
    margin-bottom: 0;
    text-align: center;
    /* font-size: large; */
  }
  .alert {
    padding: 12px;
    border-radius: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    
}

.info {
    background-color: #4285f4;
    border: 2px solid #4285f4;
    -webkit-animation: info 2s infinite;
            animation: info 2s infinite;
}
  @-webkit-keyframes info {
    0% {
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }
    
    70% {
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }
    
    100% {
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
  @keyframes info {
    0% {
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }
    
    70% {
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }
    
    100% {
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }


button.close {
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0
}
.close {
    font-size: 24px;
    color: #fff;
    opacity: 0.9;
    font-weight: normal
}
.close:hover  {
  color: #fff;
}

.alert_success {
    background-color: #09c97f;
    border: 2px solid #09c97f
}

.advarsel {
    background-color: #ffd650;
    border: 2px solid #ffd650;
    color: #000;
    font-weight: 500;
    -webkit-animation: advarsel 2s infinite;
            animation: advarsel 2s infinite;
}
  .close-advarsel {
    color: #000 !important;
    text-shadow: 0 !important;
  }
  .close-advarsel:hover {
    color: #000 !important;
  }
    @-webkit-keyframes advarsel {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
      }
      
      70% {
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
      }
    }
    @keyframes advarsel {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
      }
      
      70% {
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
      }
    }

.feilmelding {
    background-color: #CD2027;
    border: 2px solid #CD2027;
	  -webkit-animation: feilmelding 2s infinite;
	          animation: feilmelding 2s infinite;
}
  @-webkit-keyframes feilmelding {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    100% {
      
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
  @keyframes feilmelding {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }
    100% {
      
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }

.hvit {
  border: 1px solid #000;
  color: #000;
  -webkit-animation: hvit 2s infinite;
          animation: hvit 2s infinite;
}
  .close-hvit {
    color: #000 !important;
    text-shadow: 0 !important;
  }
  .close-hvit:hover {
    color: #000 !important;
  }
    @-webkit-keyframes hvit {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
      }
      
      70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
      
      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
    @keyframes hvit {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
      }
      
      70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }
      
      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }

.fade_info {
    background-color: #d9e6fb;
    border: 1px solid #4285f4
}

.fade_info .close {
    color: #4285f4
}

.fade_info strong {
    color: #4285f4
}

.fade_success {
    background-color: #c9ffe5;
    border: 1px solid #09c97f
}

.fade_success .close {
    color: #09c97f
}

.fade_success strong {
    color: #09c97f
}

.fade_warning {
    background-color: #fff0cc;
    border: 1px solid #f8b15d
}

.fade_warning .close {
    color: #f8b15d
}

.fade_warning strong {
    color: #f8b15d
}

.fade_feilmelding {
    background-color: #ffdbdb;
    border: 1px solid #f95668
}

.fade_feilmelding .close {
    color: #f95668
}

.fade_feilmelding strong {
    color: #f95668
}

.icon-close {
  color: #000;
  cursor: pointer;
  font-size: larger;
}
/* Alert Styles End */

.modern-bg {
  background: url(/static/media/modern-bg-img.ad1140bc.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
.modern-content {
  /* height: 600px; */
  padding-bottom: 40px;
  justify-content: space-between;
  
}
.modern-textbox {
  background-color: #fff;
  padding: 2rem 2rem;
  margin-top: 2rem;
  align-self: flex-end;
  border: 1px solid #232629;
  height: 95%;
}
.news-box {
  background-color: #fff;
  padding: 2rem 2rem;
  margin-top: 2rem;
  align-self: flex-start;
  border: 1px solid #232629;
}

.tkg {
  background: no-repeat top url(/static/media/skilt_small.2e1ff8a4.png);
  min-width: 370px;
}
.tkg-txt {
  text-align: center;
  padding-top: 20%;
  padding-bottom: 25%;
  color: #fff;

}

.car-absolute {
  position: absolute;
  left: 0;
  margin-left: 1%;
  margin-top: 10%;
  height: 254px;
  width: 500px;
  background: no-repeat url(/static/media/slider-bmw.f810d88f.png);
  background-size: contain;
}

.tkg-image {
  /* background: url(tkg-rom.webp);
  background-position: center; */
  margin-bottom: 1rem;
  /* align-self: baseline; */
}
.tkg-image img{
  max-width: 460px;
}
.main-content {
  background-color: rgb(236, 236, 236);
  /* height: 600px; */
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.align-button {
  text-align: right;
}
/* .align-center {
  text-align: center;
} */
.services {
  background-color: #232629;
  background-image: url(/static/media/proff-car-front-opacity68.16d7dba9.png);
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: hard-light;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.service-selector {
  background-color: #343A40;
  height: 70px
}
.service-buttons {
  display: flex;
}
.service-button {
  color: #fff;
  width: 8.1rem;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0px 4px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}
.service-button:hover {
  background-color: #CD1F26;
  cursor: pointer;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
 
  
}
.service-button-active {
  color: #fff;
  background-color: #CD1F26;
  width: 8.1rem;
  height: 70px;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
  padding: 0px 4px;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}

.service-icon {
  font-size: 1.4rem;
  letter-spacing: 3px;
  /* margin-left: 6px; */
}

.klasseb {
  padding: 15px;
}

.flex {
  display: flex;
}
.flex-space-between {
  justify-content: space-around;
}

.bg-blue {
  background-color: #a8cffe;
  border-bottom: 1px solid #666; 
  box-shadow: 2px 8px 14px 3px #666666;
  margin-bottom: 8px;
  
}
.fb-heading {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.fb-page {
  justify-content: center;
}

.fb-post-wrapper {
  background-color: #fff;
  border: 1px solid #232629;
}

.facebook-section {
  display: flex;
  /* background-color: #a8cffe;
  background-image: url(fb-follow-arrow.png);
  background-position: left;
  background-repeat: no-repeat; */
  /* border-left: 170px solid transparent;
  border-image: url(fb-follow-arrow.png);
  border-image-repeat: stretch; */
  background:  url(/static/media/fb-background.a1e82d2f.png);
  background-size: contain;
  padding-bottom: 15px;
}

.scroll-top {  
  display: block;
  top: 100vh;
  position: absolute;
  right: 26px;
}
.hide-scroll-top {
  display: none;
}

.scroll {
  background-color: #cacaca;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: block;
  float: right;
  margin: 0;
  padding: 13px 21px;
  position: fixed;
  right: 26px;
  transition: all .3s;
  word-wrap: break-word;
}

.scroll:hover {
  background-color: #CD1F26;
  color: #fff;
}

.chat {
  background-color: #CD1F26;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
  box-sizing: border-box;
  cursor: pointer;
  float: right;
  margin: 0;
  padding: 13px 21px;
  position: fixed;
  right: 100px;
}

.footer-section {
  padding-top: 20px;
  justify-content: space-between;
}
.footer-area {
  align-self: center;
}

.copy-area {
  padding-top: 20px;
}

.proff-bottom-line {
  display: block;
  background-color: #CD1F26;
  height: 5px;
}

.card-header {
  text-align: center;
}

/* .tkg-card-01, .tkg-card-02, .tkg-card-03 {
  margin-left: 1.5rem;
} */


/* Pages */

.heading {
  /* padding: 44px 0; */
  padding-top: 44px;;
  border-bottom: 2px solid #CD1F26;
  justify-content: space-between;
  align-items: center;
}
.heading-icon {
  color: #CD1F26;
  font-size: 3rem;
}

.page-content {
  padding: 44px 15px;
}

/* Klasseb page */

.card {
  border: 0;
}

.bg-light {
  background-color: #eee !important;
}
.bg-primary {
  background-color: #CD1F26 !important;
}
.tab-content {
  color: #000;
}


.tab-1 {
  background-color: #fff;
  background: no-repeat url(/static/media/tkg-rom.ff45b4f4.png);
  background-attachment: fixed;
  background-size: cover;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-2 {
  background: no-repeat url(/static/media/driving.4a3fa1e6.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-3 {
  background: no-repeat url(/static/media/car-winter.7aca381c.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-4 {
  background: no-repeat url(/static/media/driving-wv.9c656fa1.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  background-position: center;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}

/* Klasse BE Page */
.tab-be-1 {
  background-color: #fff;
  background: no-repeat url(/static/media/be-trinn.bg-01.288821b9.png);
  background-attachment: fixed;
  background-size: cover;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-be-2 {
  background: no-repeat url(/static/media/be-trinn.bg-02.b7273693.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-be-3 {
  background: no-repeat url(/static/media/be-trinn.bg-03.e8014b4a.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-be-4 {
  background: no-repeat url(/static/media/be-trinn.bg-04.b577bd4c.png);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  background-position: center;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}

/* TKG Page */
.tab-tkg-1 {
  background-color: #fff;
  background: no-repeat url(/static/media/tkg-rom-wider.18e1e545.png);
  background-attachment: fixed;
  background-size: cover;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}
.tab-tkg-2 {
  background: no-repeat url(/static/media/modern-bg-img.ad1140bc.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  border-top: 3px solid #CD1F26;
  border-bottom : 3px solid #CD1F26;
  color: #eee;
  min-height: 460px;
}

/* Price Page */

.price-table {
  margin-top: 10px;
}

/* Toggle button */
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #CD1F26;
  background-color: #CD1F26;
}
.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #CD1F26 solid 1px;
      
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #ca696d;
}

/* Kurs page */

.responsive-iframe {
  position: relative;
  padding-bottom: 58%;
  height: 0;
}
.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-iframe--4\:3 {
  padding-bottom: 75%;
}

.kampanje-tekst {
  color: #CD1F26;
}

 /* Kontakt Oss page */
.teacher-section {
    background-color: #232629;
    /* background-image: url(/static/media/proff-car-front-opacity68.16d7dba9.png);
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-blend-mode: hard-light; */
    border-top: 3px solid #CD1F26;
    border-bottom: 3px solid #CD1F26;
    color: #eee;
    /* min-height: 460px; */
}
.info-row a {
  text-decoration: none;
}
.info-box {
  display: 'flex';
  padding: 30px;
  height: 10rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.2rem;
  cursor: pointer;
}
.info-box i {
  font-size: 2rem;
  color: #CD1F26;
}

.info-box:hover {
  /* transform: scale(1.01); */
  /* transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in; */
  border-bottom: 5px solid #CD1F26;
}

.teacher-content {
  background-color: #fff;
  /* padding: 1rem 1rem; */
  margin: 2rem 0;
  align-self: flex-end;
  border: 1px solid #232629;
  padding-left: 0;
  border-radius: 6px;
}

.contact-form{
  float:right;
  width:440px;
  height:440px;
  background:#fff;
  border:1px solid #CD1F26;
  border-radius:6px;
  box-shadow:1px 5px 9px -3px #666;
  /* margin:calc(50vh - 220px) auto; */
  padding:20px 30px;
  max-width:calc(100vw - 40px);
  box-sizing:border-box;
  /* font-family:'Montserrat',sans-serif; */
  position:relative
}

.contact-form h2{
  margin:10px 0;
  padding-bottom:10px;
  width:280px;
  color:#212529;
  border-bottom:3px solid #CD1F26
}

.contact-form input{
  width:100%;
  padding:10px;
  box-sizing:border-box;
  background:none;
  outline:none;
  resize:none;
  border:0;
  /* font-family:'Montserrat',sans-serif; */
  transition:all .3s;
  border-bottom:2px solid #78788c;
}
  .contact-form input:focus{
    border-bottom:2px solid #CD1F26
  }

.contact-form p:before{
  content:attr(type);
  display:block;
  margin:28px 0 0;
  font-size:14px;
  color:#212529;
}

.contact-form button{
  float:right;
  padding:8px 12px;
  margin:8px 0 0;
  border: 1px solid transparent;
  /* font-family:'Montserrat',sans-serif; */
  /* border:2px solid #78788c; */
  background:#CD1F26;
  color:#fff;
  cursor:pointer;
  border-radius: 0.25rem;
  transition:all .3s;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}
.contact-form button:hover{
  font-weight: inherit;
  background:#fff;
  color:#000;
  border: 1px solid #CD1F26;
  transition:all .3s;
}
.teacher-text-container {
  color: #000;
  margin: 1rem;
  flex-direction: column;
}
.teacher-text {
  margin-top: 1rem;
  max-width: 100vw;
}

.teacher-heading {
  color: #000;
  border-bottom: 2px solid #CD1F26;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 100vw;
  
} 

.teacher-text blockquote  {
  /* color: #CD1F26; */
  color: 232629;
  font-weight: bold;
  background: #fff;
  border-left: 10px solid #CD1F26;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;
}
.teacher-text blockquote:before {
  color: #CD1F26;
  font-weight: bold;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
.teacher-text blockquote p {
  display: inline;
}

.teacher-button {
  width: 170px;
}

/* Responsive styles */

@media only screen and (max-width: 1200px) {
  .display-6 {
    font-size: 1.6rem;
  }
  .display-4 {
  font-size: 2.6rem;
  }
  .flex {
    flex-wrap: wrap;
    /* justify-content: center; */
  }
  .tkg-image img{
    max-width: 365px;
  }
  .mainHeader {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1198) {
  /* .tkg-card-03 {
    margin-left: 0 !important;
    margin-top: 2rem;
  } */
}

@media only screen and (max-width: 1024px) {
  
  .header {
    max-width: 920px !important;
    font-size: 14px;
  }
  .App-logo {
    width: 200px;
  }
    .info-box {
    height: 12rem;
  }
  .lead {
    font-size: 1.2rem;
  }
  .modern-textbox {
    width: 100%;
    min-width: 420px;
  }
  .tkg-image img{
    max-width: 100%;
  }
  .card {
    min-width: 300px !important;
  }
  .cards-container {
    justify-content: center;
  }
  .teacher-text blockquote {
    margin: 1em 10px;
  }
  .CookieConsent {
    width: 40% !important;
  }
}

@media only screen and (max-width: 991px) {
  .align-button {
    text-align: left;
  }
  .service-buttons {
    justify-content: center;
  }
 }

@media only screen and (max-width: 767px) { 
  .App-logo {
      width: 120px;
  }
  .header-icon {
    padding-left: 0px;
  }
  .modern-textbox {
    width: 100%;
    min-width: 100%;
  }
  .footer-area img {
    max-width: 13rem;
  }
  .card {
    width: 300px !important;
  }
  .contact-form {
    float: left;
  }
  .contact-form h2 {
    width: 100%;
  }
  .contact-info-address {
    margin-top: 2rem;
  }
  
  .CookieConsent {
    width: 100vw !important;
  }
  
}

@media only screen and (max-width: 610px) {
  .btn-elev-login {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  .header {
    max-width: 920px !important;
    font-size: 0.8rem;
  }
  .teacher-content {
    padding-left: 0;
    padding-right: 0;
  }
  .modern-textbox {
    min-width: 100%;
  }
  .car-absolute {
    display: none;
  }
  
  /* .fb-page {
    justify-content: flex-end;
  } */
 }

 @media only screen and (max-width: 540px) {
 .fb-post-wrapper {
  width: 100vw;
}
}

 @media only screen and (max-width: 532px) {
  .hide-from-532px {
    display: none;
  }
  .header {
    justify-content: space-evenly;
  }
  .tkg {
    min-width: 100%;
  }
  
 }

 @media only screen and (max-width: 477px) { 
  .col-contact-info {
    margin-top: 2rem;
  }
  .teacher-content img{ 
    max-width: 100% !important;
  }
  .service-buttons {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .display-4 {
    font-size: 2rem;
 }
  .lead {
    font-size: 1.1rem;
  }
}
 
@media only screen and (max-width: 410px) { 
  .price-table {
    font-size: 0.8rem;
  }
  .service-buttons {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
 }


 @media only screen and (max-width: 382px) { 
  /* .container {
    padding: 0px !important;
  } */
  .display-4 {
    font-size: 2rem; 
  }
  .display-6, .display-5 {
    font-size: 1.4rem;
  }
  .ml-4 {
    margin-left: 0 !important;
  }
  .service-button {
    font-size: 0.9rem;
  }
  .service-icon {
    font-size: 1rem;

 }
}

@media only screen and (max-width: 359px) { 
  .hide-from-360px {
    display: none;
  }
  .service-button {
    width: 7.8rem;
  }
 }

@media only screen and (max-width: 340px) { 
.btn-elev-login {
  padding: 0.3rem;
  font-size: 0.7rem;
}
.service-buttons {
  padding-right: 0px; 
  padding-left: 0px;
}
.service-icon {
  letter-spacing: 0px;
  font-size: 0.8rem;
}

.footer-section iframe {
  display: none;
}
}
@media only screen and (max-width: 319px) {
  .hide-from-319px {
    display: none;
  }
  .page-content {
    padding: 44px 8px;
  }
 }

